import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
} from '../styles';
import { Link, Svg } from './ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import arrowIcon from '../images/right-arrow.inline.svg';

const StyledNewsCard = styled.article`
  &:nth-child(even) {
    ${minBreakpointQuery.smedium`
      margin-top: 55px;
    `}
  }
`;

const StyledImageWrapper = styled(Link)`
  position: relative;
  overflow: hidden;
`;

const StyledImage = styled(GatsbyImage)`
  transition: ${standardTransition('transform')};

  &:hover {
    transform: scale(1.1);
  }
`;

const StyledIcon = styled(Svg)`
  position: absolute;
  background-color: ${standardColours.white};
  fill: ${brandColours.primary};
  width: 16px;
  height: 16px;
  bottom: 0;
  right: 0;
  padding: 14px 16px;
  box-sizing: content-box;

  ${minBreakpointQuery.small`
    width: 22px;
    height: 22px;
    padding: 20px 22px;
  `};
`;

const StyledContent = styled.div`
  position: relative;
  padding: 20px 0;
  background-color: ${standardColours.white};
`;

const StyledHeading = styled.h3`
  color: ${brandColours.primary};
  font-weight: ${fontWeights.semibold};
  ${fontSize(24)};
  line-height: 38px;

  ${minBreakpointQuery.small`
    ${fontSize(26)};
  `};

  ${minBreakpointQuery.large`
    ${fontSize(28)};
  `};
`;

const StyledDate = styled.time`
  display: block;
  ${fontSize(16)};
  color: ${brandColours.tertiary};
  margin-top: 15px;

  ${minBreakpointQuery.large`
    ${fontSize(18)};
  `};
`;

const NewsCard = ({ heading, slug, meta, image }) => {
  const urlPath = `news/${slug}`;

  return (
    <StyledNewsCard>
      <StyledImageWrapper to={urlPath}>
        <StyledImage image={image.gatsbyImageData} alt={image.alt} />
        <StyledIcon image={arrowIcon} />
      </StyledImageWrapper>
      <StyledContent>
        <StyledHeading>
          <Link to={urlPath}>{heading}</Link>
        </StyledHeading>
        <StyledDate datetime={meta.robotDate}>{meta.humanDate}</StyledDate>
      </StyledContent>
    </StyledNewsCard>
  );
};

export default NewsCard;

export const NewsCardFragment = graphql`
  fragment NewsCardFragment on DatoCmsNews {
    title
    slug
    meta {
      humanDate: firstPublishedAt(formatString: "DD MMM YYYY")
      robotDate: firstPublishedAt(formatString: "YYYY-MM-DD")
    }
    featuredImage {
      gatsbyImageData(width: 710, height: 450)
      alt
    }
  }
`;
