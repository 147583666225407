import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import NewsListing from '../components/NewsListing';

const NewsArchive = () => {
  const {
    datoCmsNewsArchive: { seoMetaTags, title, bannerText },
    allDatoCmsNews: { nodes: articles },
  } = useStaticQuery(graphql`
    query NewsArchiveQuery {
      datoCmsNewsArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
      }
      allDatoCmsNews(sort: { fields: meta___firstPublishedAt, order: DESC }) {
        nodes {
          ...NewsCardFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} text={bannerText} />
        <NewsListing
          heading="Latest News"
          items={articles}
          isNewsArchive={true}
        />
      </main>
    </Layout>
  );
};

export default NewsArchive;
