import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  sectionMargins,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container, Heading, Link, Svg } from './ui';
import arrowIcon from '../images/right-arrow.inline.svg';
import NewsCard from './NewsCard';

const StyledNewsListing = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  ${maxBreakpointQuery.smedium`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.smedium`
    display: flex;
    flex-direction: column;
  `};
`;

const StyledHeading = styled(Heading)`
  ${({ isNewsArchive }) => {
    if (isNewsArchive) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledLink = styled(Link)`
  margin: 10px 0;
  color: ${brandColours.primary};
  font-weight: ${fontWeights.medium};
  ${fontSize(19)};

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `};

  ${minBreakpointQuery.smedium`
    align-self: flex-end;
  `};

  ${minBreakpointQuery.large`
    ${fontSize(25)};
  `};
`;

const StyledLinkIcon = styled(Svg)`
  margin-left: 10px;
  width: 16px;
  fill: ${brandColours.primary};
  vertical-align: middle;

  ${minBreakpointQuery.small`
    margin-left: 12px;
    width: 18px;
  `};

  ${minBreakpointQuery.large`
    margin-left: 14px;
    width: 20px;
  `};
`;

const StyledItems = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
    column-gap: 60px;
  `};

  ${minBreakpointQuery.medium`
    gap: 30px 90px;
  `}

  ${minBreakpointQuery.mlarge`
    gap: 40px 120px;
  `}

  ${minBreakpointQuery.large`
    gap: 50px 150px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 60px 180px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    column-gap: 210px;
  `}
`;

const NewsListing = ({ heading, items, isNewsArchive }) => (
  <StyledNewsListing>
    <Container>
      <StyledHeader>
        <StyledHeading alt={true} isNewsArchive={isNewsArchive}>
          {heading}
        </StyledHeading>
        {!isNewsArchive && (
          <StyledLink to="news">
            All news <StyledLinkIcon image={arrowIcon} />
          </StyledLink>
        )}
      </StyledHeader>
      <StyledItems>
        {items.map(({ id, title, slug, featuredImage, meta }) => (
          <NewsCard
            key={id}
            heading={title}
            slug={slug}
            image={featuredImage}
            meta={meta}
          />
        ))}
      </StyledItems>
    </Container>
  </StyledNewsListing>
);

export default NewsListing;
